import { IonAvatar, IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonPage, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Home.scss';
import '../theme/custom.scss'
import { useContext, useEffect, useState } from 'react';
import { CreateAnimation, Animation } from '@ionic/react';
import { useHistory, useParams } from 'react-router';
import { AppContext, Ave } from '../App';
import { arrowForwardCircleOutline } from 'ionicons/icons';
import { Link } from 'react-router-dom';

const ProductoPublico: React.FC = () => {

    const {id} = useParams<{id:string}>();
    const context = useContext<any>(AppContext);
    const history = useHistory();

    const [audio, setAudio] = useState(new Audio());
    const [playing, setPlaying] = useState<boolean>(false);
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [image, setImage] = useState<boolean>(false);
    const [esta, setEsta] = useState<boolean>(false);
    const [ave, setAve] = useState<Ave | null>(null);
    
    
    useEffect(() => {
        validarId();
        const handleResize = () => {setWidth(window.innerWidth)}
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])
    
    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    });
    
    useEffect(() => {

        if (playing) { 
            audio.play();
        } else {
            audio.pause();
            audio.currentTime = 0;
        } 
    }, [playing]);  
    
    useEffect(() => {
        setTimeout(() => {
            setImage(!image);
        }, 2000);
    }, [image]);

    const toggle = () => setPlaying(!playing);
    
    const validarId = () => {
        const avecita = context.aves.get.find((ave:Ave) => ave.id === id);
        if (avecita) {
            setAve(avecita);
            setEsta(true);
            setAudio(new Audio(avecita.audio));
        } else {
            context.error.set({error: true, message: "Ese pájaro no existe"})
            history.push('/');
        }
    };

    const navegarHome = () => {
        audio.pause();
        audio.currentTime = 0;
        history.push('/');
    }

    return (
        <IonPage >
            {/* <IonHeader>
                <IonGrid  class='ion-no-padding'>
                    <IonToolbar color="light">
                        <IonButtons slot='start'>
                            <IonButton color='medium' onClick={() => {navegarHome()}}>More</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonGrid>
            </IonHeader> */}
            {esta && 
            <IonContent fullscreen  className={`background-${id}`} >
                <IonGrid class='ion-no-padding' fixed>
                    <IonRow >
                        <IonCol sizeXs='12' sizeLg='6'>
                            <IonImg src={`/assets/images/${id}/principal.png`}/>
                        </IonCol>
                        <IonCol sizeXs='12' sizeLg='6' className={`fondo-ppal-${id}`}>
                            {image ? 
                            <IonImg src={`/assets/images/${id}/puntos-pajaros.png`}/>
                            :
                            <IonImg src='/assets/images/puntos.png'/>
                            }
                        </IonCol>
                    </IonRow>
                    {/* CAMORRA */}
                    {width > 991 ?
                        <IonRow className={`barra1440-${id}`} class="ion-align-items-end">
                            <IonCol size="1" offset='5'>
                                <img src={playing? `assets/images/${id}/boton-stop.png` : `assets/images/${id}/boton.png`}  className='boton' onClick={() => {toggle()}}/>
                            </IonCol>
                            {/* <IonCol size='6' hidden={!playing}>
                                <IonText color='medium' className='texto-creditos'>
                                    <p>
                                    Credits: {ave?.soundCredits}
                                    </p>
                                </IonText>
                                <IonText color='medium' className='texto-creditos'>
                                    <p>
                                        License: <a target="_blank" rel="noopener noreferrer" href="https://creativecommons.org/licenses/by-nc-nd/4.0/" className='vinculo'>Creative Commons Attribution-NonCommercial-NoDerivs 4.0 </a>
                                    </p>
                                </IonText>
                            </IonCol> */}
                            {/* <img hidden={playing} src={`assets/images/${id}/boton1440.png`} onClick={() => {toggle()}} className='pointer'/>
                            <img hidden={!playing} src={`assets/images/boton1440-dimmed.png`} /> */}
                        </IonRow>
                    :
                        <IonRow className={`barra720-${id} pointer`} class="ion-align-items-end">
                            <IonCol size='2' offset='5'>
                                <img src={playing? `assets/images/${id}/boton-stop.png` : `assets/images/${id}/boton.png`} className='boton' onClick={() => {toggle()}}/>
                            </IonCol>
                            {/* <IonCol size='5' hidden={!playing}>
                                <IonText color='medium' className='texto-creditos'>
                                    <p>
                                        Credits: {ave?.soundCredits}
                                    </p>
                                </IonText>
                                <IonText color='medium' className='texto-creditos'>
                                    <p>
                                        License: <a target="_blank" rel="noopener noreferrer" href="https://creativecommons.org/licenses/by-nc-nd/4.0/" className='vinculo'>Creative Commons Attribution-NonCommercial-NoDerivs 4.0 </a>
                                    </p>
                                </IonText>
                            </IonCol> */}
                            {/* <img hidden={playing} src={`assets/images/${id}/boton720.png`} onClick={() => {toggle()}} className='pointer'/>    
                            <img hidden={!playing} src={`assets/images/boton720-dimmed.png`} />     */}
                        </IonRow>
                    }
                    <IonRow className={`background-${id}`} class='ion-justify-content-center'>
                        <IonCol sizeXs='12' sizeMd='6'>
                            <IonImg src={`assets/images/${id}/pata.png`} />
                        </IonCol>
                    </IonRow>
                    <IonRow class='ion-justify-content-center'>
                        <IonCol size='12'>
                            <a href='https://alas-4725.myshopify.com/' target="_blank" rel="noopener noreferrer">
                                <img src="assets/images/store-link.png"/>
                            </a>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol class='ion-padding'>
                            <IonText color='medium' className='texto-creditos'>
                                <p>
                                    Sound credits: {ave?.soundCredits}
                                </p>
                            </IonText>
                            <IonText color='medium' className='texto-creditos'>
                                <p>
                                    License: <a target="_blank" rel="noopener noreferrer" href="https://creativecommons.org/licenses/by-nc-nd/4.0/" className='vinculo'>Creative Commons Attribution-NonCommercial-NoDerivs 4.0 </a>
                                </p>
                            </IonText>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            }
        </IonPage>
    );
};

export default ProductoPublico;


