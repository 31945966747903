// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
export default {
  apiKey: "AIzaSyDwG984n8NoYGUf9qHTg1QTSatez4-ugPg",
  authDomain: "alas-boutique.firebaseapp.com",
  projectId: "alas-boutique",
  storageBucket: "alas-boutique.appspot.com",
  messagingSenderId: "223439448298",
  appId: "1:223439448298:web:77dd6177fd54781cf40f93",
  measurementId: "G-3DJS76D1WT"
};

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);