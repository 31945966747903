import { IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonImg, IonPage, IonRow } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AppContext, Ave, Error } from "../App";
import ExploreContainer from "../components/ExploreContainer";

const Home:React.FC = () => {

    const context = useContext<any>(AppContext);
    const [error, setError] = useState<Error | null>(null);
    const history = useHistory();
    const [audio, setAudio] = useState(new Audio());

    useEffect(() => {
        console.log(context.aves.get)
        if (context.error.get) {
            setError(context.error.get);
            context.error.set(null)
        }
    }, []);

    const goTo = (id: string) => {

        history.push(`/aves/${id}`)
    }

    return(
        <IonPage>
            <IonContent color="light">
                <IonGrid fixed>
                    {error && 
                        <IonRow>
                            <IonCol size="12">
                                <IonCard>
                                    <IonCardContent>
                                        {error.message}
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    }
                    <IonRow>
                        {context.aves.get.map((ave:Ave, index:number) => {
                            return(
                                <IonCol sizeXs="12" sizeMd="6" key={index}>
                                    <IonCard button onClick={() => goTo(ave.id)}>
                                        <IonImg src={`/assets/images/${ave.id}/principal.png`}></IonImg>
                                    </IonCard>
                                </IonCol>
                            )
                        })}
                    </IonRow>

                        {/* <ExploreContainer/> */}

                </IonGrid>
            </IonContent>
        </IonPage>
    )
};

export default Home;

