import React, {useEffect, useState} from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { doc, getFirestore } from 'firebase/firestore';
import { FirebaseAppProvider, FirestoreProvider, useFirestoreDocData, useFirestore, useFirebaseApp } from 'reactfire';

import Home from './pages/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import ProductoPublico from './pages/ProductoPublico';
import ProductoPrivado from './pages/ProductoPrivado';

setupIonicReact();
export const AppContext = React.createContext({});

const App: React.FC = () => {

    const [aves, setAves] = useState<Ave[]> ([
        {
            id: "bahama-swallow",
            audio: '/assets/sounds/bahama-swallow.mp3',
            soundCredits:'Andrew Spencer, XC331604. Accessible at www.xeno-canto.org/331604.'
        },
        {
            id: "beach-kingfisher",
            audio: '/assets/sounds/beach-kingfisher.mp3',
            soundCredits: 'Frank Lambert, XC404107. Accesible en www.xeno-canto.org/404107.'
        },
        {
            id: "blue-jay",
            audio: '/assets/sounds/blue-jay.mp3',
            soundCredits:'Thomas Ryder Payne, XC721248. Accesible en www.xeno-canto.org/721248.'
        },
        {
            id: "green-kingfisher",
            audio: '/assets/sounds/green-kingfisher.mp3',
            soundCredits:'Manuel Grosselet, XC615056. Accesible en www.xeno-canto.org/615056.'
        },
        {
            id: "magnificent-frigatebird",
            audio: '/assets/sounds/magnificent-frigatebird.mp3',
            soundCredits:'Ricardo José Mitidieri, XC698965. Accesible en www.xeno-canto.org/698965.'
        },
        {
            id: "purple-euphoria",
            audio: '/assets/sounds/purple-euphoria.mp3',
            soundCredits:'Jayrson Araujo de Oliveira, XC738674. Accesible en www.xeno-canto.org/738674.'
        },
        {
            id: "scarlet-tanager",
            audio: '/assets/sounds/scarlet-tanager.mp3',
            soundCredits:'David Welch, XC572254. Accesible en www.xeno-canto.org/572254.'
        },
        {
            id: "southern-martin",
            audio: '/assets/sounds/southern-martin.mp3',
            soundCredits:'Étienne Leroy, XC396792. Accesible en www.xeno-canto.org/396792.'
        },
        {
            id: "white-hawk",
            audio: '/assets/sounds/white-hawk.mp3',
            soundCredits:'Cid Espínola, XC111828. Accesible en www.xeno-canto.org/111828.'
        },
        {
            id: "bald-eagle",
            audio: '/assets/sounds/bald-eagle.mp3',
            soundCredits:'Beverly Hallberg, XC636910. Accesible en www.xeno-canto.org/636910.'
        },
        {
            id: "brown-pelican",
            audio: '/assets/sounds/brown-pelican.mp3',
            soundCredits:'Judith PRIAM, XC357928. Accesible en www.xeno-canto.org/357928.'
        }
    ]);
    const [error, setError] = useState<Error | null>(null)
    
    const store = {
        aves:   {get: aves,     set: setAves}, 
        error:  {get: error,    set: setError},
    };

    return(
        <AppContext.Provider value={store}>
            <IonApp>
                <IonReactRouter>
                    <Switch>
                        <Route exact path="/home">
                            <Home />
                        </Route>
                        <Route exact path='/aves/:id'>
                            <ProductoPublico />
                        </Route>
                        <Route exact path='/aves/:id/privado'>
                            <ProductoPrivado />
                        </Route>
                        <Route exact path="/">
                            <Redirect to="/home" />
                        </Route>
                        <Route path="*">
                            <Home/>
                        </Route>
                    </Switch>
                </IonReactRouter>
            </IonApp>
        </AppContext.Provider>

    )
};

export default App;

export interface Ave {
    id: string;
    audio: string;
    soundCredits: string;
};

export interface Error {
    error: boolean;
    message: string;
}