
import reactfireConfig from "../reactfireConfig";
import {initializeApp} from 'firebase/app';
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import {getFirestore} from 'firebase/firestore';
import { useEffect } from "react";
import { collection, getDocs } from 'firebase/firestore';

const ProductoPrivado: React.FC = () => {

    const firebaseApp = initializeApp(reactfireConfig);
    const auth = getAuth(firebaseApp);
    const db = getFirestore(firebaseApp);

    useEffect(() => {
        // getCities(db)
    }, []);

    async function getCities(db:any) {
        const citiesCol = collection(db, 'usuarios');
        const citySnapshot = await getDocs(citiesCol);
        const cityList = citySnapshot.docs.map(doc => doc.data());
        console.log(cityList)
        return cityList;
      }


    return (<></>)
    
};

export default ProductoPrivado;